import React from 'react'

function Header() {
  return (
    <div data-elementor-type="header" data-elementor-id="928" className="elementor elementor-928 elementor-location-header">
   <section className="elementor-section elementor-top-section elementor-element elementor-element-752cd09 elementor-section-height-min-height elementor-section-items-bottom elementor-section-boxed elementor-section-height-default" data-id="752cd09" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
      <div className="elementor-container elementor-column-gap-no">
         <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ffb576c" data-id="ffb576c" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
               <section className="elementor-section elementor-inner-section elementor-element elementor-element-ea293cc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ea293cc" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-default">
                     <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1355460" data-id="1355460" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                           <div className="elementor-element elementor-element-bee6b08 elementor-widget elementor-widget-theme-site-logo elementor-widget-image" data-id="bee6b08" data-element_type="widget" data-widget_type="theme-site-logo.default">
                              <div className="elementor-widget-container">
                                 <a href="https://idevelopedskills.com">
                                 <img width="1008" height="696" src="https://idevelopedskills.com/wp-content/uploads/2021/06/ideveloped-skills-logo-white-png-1-126x87.png" className="attachment-full size-full wp-image-1542" alt="" loading="lazy" srcset="https://idevelopedskills.com/wp-content/uploads/2021/06/ideveloped-skills-logo-white-png-1.png 1008w, https://idevelopedskills.com/wp-content/uploads/2021/06/ideveloped-skills-logo-white-png-1-300x207.png 300w, https://idevelopedskills.com/wp-content/uploads/2021/06/ideveloped-skills-logo-white-png-1-768x530.png 768w, https://idevelopedskills.com/wp-content/uploads/2021/06/ideveloped-skills-logo-white-png-1-126x87.png 126w" sizes="(max-width: 1008px) 100vw, 1008px" />								</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   </section>
</div>
  )
}

export default Header