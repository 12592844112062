import React, { useContext, useEffect } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import AppContext from '../../store/AppContext';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function StripeContainer() {
      const {clientSecret} = useContext(AppContext) 

      const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret
      };

      useEffect(()=>{
        console.log(clientSecret)
      },[])
    
      return (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      );
}

export default StripeContainer