import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import AppContext from '../store/AppContext'
import { useSearchParams } from 'react-router-dom'
import { useCart } from 'react-use-cart'

function RefundPolicyModal({dists}) {
    const {items} = useCart()
    const {changeEmail,changeStage,placeOrder} = useContext(AppContext)
    const [searchParams] = useSearchParams()
    const [agree,setAgree] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [email,setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')

    function handleEmailChange(e){
        setEmail(e.target.value)
    }

    function handleConfirmEmailChange(e){
        setConfirmEmail(e.target.value)
    }

    function checkExtraFields(){
        let has = false;
        dists.forEach((item)=>{
            if(item.extrafields.length > 0) has = true;
        })
        console.log("Has",has)
        return has
    }

    function handleProceed(){
        let refundModalEl = document.getElementById('RefundModal');
        let refundModal = window.bootstrap.Modal.getInstance(refundModalEl)
        refundModal.hide()

        // If not extrafields
        if(!checkExtraFields()){

            let fields = items.map((item)=>{
                return {
                    'distId' : item.id,
                    'qty' : item.quantity,
                    'orderDistExtraFields' : []
                }
            })
            let orderDists = {fields}
            placeOrder(orderDists,searchParams.get('id'))
            return
        }

        changeStage('extraFields')
    }

    useEffect(()=>{
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(confirmEmail)){
            if(email === confirmEmail && agree){
                setIsValid(true)
                changeEmail(email)
            }else{
                setIsValid(false)
            }
        }else{
            setIsValid(false)
        }
        if(agree){
            setIsValid(true)
        }
    },[email,confirmEmail,agree])

  return (
    <div className="modal fade" id="RefundModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Refund Policy</h5>
                <button type="button" id='closeModal' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <p className="justify-content-evenly text-black">
                </p>
                
                <h5 className="fw-bold">Buying Signups:</h5>
                Buyers are solely responsible for completing their transactions in their Signups, including the payment of applicable fees. By committing to Signup, you are entering into a binding contract with the Seller to purchase the applicable Signup.All sales are final, and except for cancelled Signups, you will not receive a refund for any completed purchase. You cannot change or cancel orders after the sale is complete.              
                <br/>
                <br/>
                <h5 className="fw-bold">Agreement:</h5>
                You agree to supply iDevelop with the appropriate information necessary for delivery of signups, including, but not limited to, email address and physical address (if applicable). If you provide incorrect information for the delivery of Signups, or you are unavailable to receive the signups when they are delivered, you will not be eligible for a refund or Buyer Guarantee.
            </div>
            <div className="modal-footer justify-content-flex-start">
                <div className="form-check w-100">
                    <input className="form-check-input" type="checkbox" checked={agree} id="flexCheckChecked" onChange={(e)=>setAgree(e.target.checked)} />
                    <label className="form-check-label" for="flexCheckChecked">
                        Agree to the terms and conditions
                    </label>
                </div>
                {agree && <div className='row w-100'>
                    <div className='col-12 col-md-6'>
                        <div className="mb-3">
                            <label for="emailFormControlInput" className="form-label">Email</label>
                            <input type="email" className="form-control" id="emailFormControlInput" value={email} onChange={(e)=>handleEmailChange(e)} placeholder="name@example.com" />
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className="mb-3">
                            <label for="confirmEmailFormControlInput" className="form-label">Confirm Email</label>
                            <input type="email" className="form-control" id="confirmEmailFormControlInput" value={confirmEmail} onChange={(e)=>handleConfirmEmailChange(e)} placeholder="name@example.com" />
                        </div>
                    </div>
                </div>}
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={()=>handleProceed()} disabled={!isValid}>Pay Now</button>
            </div>
            </div>
        </div>
    </div>
  )
}

export default RefundPolicyModal