import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { useCart } from 'react-use-cart'

const initialState = {
    league : null,
  activeStage: 'leagues',
//   activeStage: 'extraFields',
  clientSecret: null,
  paymentIntentId: null,
  email: null,
}

const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_LEAGUE":
        return {
            ...state,
            league: action.payload
        }
      case "CHANGE_STAGE":
        return {
            ...state,
            activeStage: action.payload
        }
      case "SET_CLIENT_SECRET":
        return {
            ...state,
            clientSecret: action.payload
        }
      case "SET_PAYMENT_INTENT_ID":
            return {
                ...state,
                paymentIntentId: action.payload
            }
      case "SET_EMAIL":
        return {
            ...state,
            email: action.payload
        }
      default: {
          return { ...state }
      }
    }
}

const AppContext = createContext({
    ...initialState,
    changeStage: (name) => Promise.resolve(),
    placeOrder: (orderDists) => Promise.resolve(),
    completeOrder: () => Promise.resolve(),
    cancelOrder: () => Promise.resolve(),
    changeLeague: (league) => null,
    changeEmail: () => null
})

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const {cartTotal,items,emptyCart} = useCart()

    function changeStage(name){
        dispatch({
            type: 'CHANGE_STAGE',
            payload: name
        })
    }

    function changeLeague(league){
        dispatch({
            type: 'CHANGE_LEAGUE',
            payload: league
        })
    }

    function changeEmail(email){
        dispatch({
            type: 'SET_EMAIL',
            payload: email
        })
    }

    async function placeOrder(orderDists,id){
        let leagueId = id;
        let clientEmail = state.email;

        const order = {
            leagueId,
            clientEmail,
            orderDists: [...orderDists.fields]
        }

        try {
            const { data } = await axios.post(process.env.REACT_APP_API_URL + '/api/checkout',order)
            const {status,object,message} = data
            dispatch({
                type: 'SET_PAYMENT_INTENT_ID',
                payload: object
            })
            if(cartTotal === 0){
                await completeOrder(object)
                changeStage('success')
                return Promise.resolve()
            }else if(status === 'Success'){
                const { data } = await axios.get(process.env.REACT_APP_API_URL + '/api/checkout/clientsecret?paymentIntentId='+object)
                const {object:innerObject,message,status} = data
                if(status === 'Success'){
                    dispatch({
                        type: 'SET_CLIENT_SECRET',
                        payload: innerObject
                    })
                    changeStage('checkoutForm')
                }
                if(data) return Promise.reject()
            }else{
                return Promise.resolve()
            }
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    }

    async function cancelOrder(paymentIntentId){
        const { data } = await axios.get(process.env.REACT_APP_API_URL + '/api/checkout/cancelOrder?paymentIntentId='+paymentIntentId)
        if(data.status === 'Error'){
            //When error
            console.log(data.message)
            dispatch({
                type: 'SET_CLIENT_SECRET',
                payload: null
            })
        }else if(data.status === 'Success'){
            dispatch({
                type: 'SET_CLIENT_SECRET',
                payload: null
            })
            changeStage('cancel')
        }
        return data;
    }

    async function completeOrder(paymentIntentId){
        const { data } = await axios.get(process.env.REACT_APP_API_URL + '/api/checkout/completeOrder?paymentIntentId='+paymentIntentId)
        if(data.status === 'Error'){
            //When error
            console.log(data.message)
            dispatch({
                type: 'SET_CLIENT_SECRET',
                payload: null
            })
        }else if(data.status === 'Success'){
            dispatch({
                type: 'SET_CLIENT_SECRET',
                payload: null
            })
            changeStage('success')
        }
        return data;
    }

    useEffect(() => {
        ; (async () => {
            try {
                    // let id = new URLSearchParams(window.location.search).get('Id')
                    //create-test-2

            } catch (err) {
                // dispatch({type: 'INIT'})
            }
            emptyCart()
        })()
    }, [])

    return (
        <AppContext.Provider
            value={{
                ...state,
                changeStage,
                placeOrder,
                completeOrder,
                changeEmail,
                changeLeague,
                cancelOrder
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext