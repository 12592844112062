import React from 'react'

function NotFound() {
  return (
    <div className='contianer'>
      <div class="d-flex align-items-center justify-content-center vh-50">
            <div class="text-center">
                <h1 class="display-1 fw-bold text-white">404</h1>
                <p class="fs-3 text-white"> <span style={{color:'#9d0404'}}>Opps!</span> Page not found.</p>
                <p class="lead text-white">
                    The page you’re looking for doesn’t exist.
                  </p>
                <a href="https://idevelopedskills.com/" class="btn btn-primary" style={{background:'#9d0404',border:'none'}}>Go Home</a>
            </div>
        </div>
    </div>
  )
}

export default NotFound