import './App.css';
import Header from './components/Header';
import {RouterProvider,createBrowserRouter} from 'react-router-dom'
import Root from './Pages/Root';
import NotFound from './Pages/NotFound';

function App() {


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />
    },
    {
      path: '*',
      element: <NotFound/>
    }
  ]);

  return (
    <>
    <Header/>
    <RouterProvider router={router}/>
    </>
  );
}

export default App;
