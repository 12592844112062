import React, { useEffect, useState } from 'react'
import { useCart } from 'react-use-cart';

function LeagueItems({items}) {
  const [tickets,setTickets] = useState()
  const {addItem,getItem,updateItemQuantity} = useCart()
  const [isLoading,setIsLoading] = useState(true)

  function handleQuantityChange(type,id){
    let obj = {};
    if(getItem(id)){
        if(type === 'plus'){
            console.log("Plus", getItem(id).quantity + 1)
            updateItemQuantity(id, getItem(id).quantity + 1)
        }else{
            updateItemQuantity(id, getItem(id).quantity - 1)
        }
    }else{
        tickets.forEach((ticket)=>{
            if(ticket.id === id){
                if(type === 'plus'){
                    obj = { id, name: ticket.name, price: ticket.price, quantity: 1,extrafields: ticket.extrafields}
                }
            }
        });
        addItem(obj)
    }
  }

  function renderQuantity(item){
    if(getItem(item.id)){
        return <input className="ttq-input" type="number" value={getItem(item.id).quantity} min="0" readonly />
    }else{
        return <input className="ttq-input" type="number" value={'0'} min="0" readonly />
    }
    
  }

  useEffect(()=>{
    console.log('leagues',items)
    setTickets(items)
    if(items === undefined) {
        setIsLoading(false)
    }
    if(typeof items == 'object' && items.length > 0) setIsLoading(false)
  },[items])


  return (
    <div className="card">
        <div className="card-header rf-card-header d-flex justify-content-between">
            <span className="card-header-title">Distribution(s)</span>
            <span className="card-header-info">No. of item(s)</span>
        </div>
        <div className="card-body rf-card-body">
            {isLoading ? (
                <div className='text-center'>
                    <div class="spinner-border" role="status"></div>
                </div>
            ) : (
                <>
                    {tickets ? (tickets.map((item)=>{
                        return (
                            <div key={item?.id} className="ticket-types-item p-3 row">
                                <div className="ticket-types-item-info col-7 col-md-8">
                                    <div className="tti-title">{item.name}</div>
                                    <div className="tti-price">$ {item.price}</div>
                                    {item?.message && <div className="tti-desc">{item.message}</div>}
                                </div>
                                <div className="ticket-types-item-quantity col-5 col-md-4">
                                    <div className="ttq-quantity-wrapper">
                                        <span className="ttq-minus" onClick={()=>handleQuantityChange('minus',item.id)}>-</span>
                                            {renderQuantity(item)}
                                        <span className="ttq-plus" onClick={()=>handleQuantityChange('plus',item.id)}>+</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })) : (
                        <div className='p-3 row text-center'>
                            <p className='text-danger'>No distributions for this id.</p>
                        </div>
                    )}
                </>
            )}
        </div>
    </div>

  )
}

export default LeagueItems