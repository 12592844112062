import React from 'react'
import StripeContainer from './stripe/StripeContainer'

function Checkout() {
  return (
    <div className="row my-5">
        <div className='col-md-12'>
            <h4 className='text-center text-white'>Please Fill in payment information.</h4>
        </div>
        <div className="col-md-12">
          <StripeContainer/>
        </div>
    </div>
  )
}

export default Checkout