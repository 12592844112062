import React, { useContext } from 'react'
import AppContext from '../store/AppContext';

function LeagueDetails() {
    const {league} = useContext(AppContext)

    function renderDate(date){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let formattedDate = new Date(date);
        return formattedDate.toLocaleDateString('en-US',options)
    }

  return (
    <div className="card mb-3" style={{backgroundColor:'rgba(0,0,0,0.65)',color:'white'}}>
        <div className="card-body">
            <h2 className='text-center mb-4'>{league?.name}</h2>
            <div className='d-flex justify-content-around flex-wrap'>
                <div className='d-flex'><h6 style={{marginRight:'10px'}}>{'Start Date:'} </h6><p>{renderDate(league?.startDate)}</p></div>
                <div className='d-flex'><h6 style={{marginRight:'10px'}}>End Date: </h6><p>{renderDate(league?.endDate)}</p></div>
            </div>
        </div>
    </div>
  )
}

export default LeagueDetails